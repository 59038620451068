import React from "react"
import Layout from "../components/common/layout/layout"
import Navigation from "../components/common/navigation/navigation"
import Footer from "../components/sections/footer"
import Banner from "../components/sections/banner"
import SectionWrapper from "../components/sections/section-wrapper"
import SectionTitle from "../components/sections/section-title"

const AcceptedAbstracts = () => {
  const abstractPresentations = [
    {
      header: "Track 2: Encouraging students to consider computing in their future",
      abstracts: [
        {
          authors: "Andreea Molnar, Therese Keane and Rosemary Stockdale",
          country: "Australia",
          title: "Interventions, Studying IT in Secondary School and Social Support: A Glimpse into what Influences Women to Enroll in IT Degrees in Australia",
        },
        {
          authors: "Jenine Beekhuyzen Oam and Jacqueline Tate",
          country: "Australia",
          title: "The Techgirls Competition Program",
        },
      ]
    },
    {
      header: "Track 3: Recruitment and retention of girls and women in computing courses and careers ",
      abstracts: [
        {
          authors: "Mahbuba Tasmin, Nova Ahmed and Tamanna Motahar",
          country: "Bangladesh",
          title: "Rooting the Gender Polarity in Computer Science: A Study on Women in Computer Science Education in Bangladesh",
        },
        {
          authors: "Natsuko Uchida, Chuchi Montenegro, Yasushi Kuno, Hiroyasu Kakuda and Yasuichi Nakayama ",
          country: "Japan",
          title: "Introductory Programming Education with PBL for Women’s Liberal Arts University",
        },
        {
          authors: "Anjali Gupta and Anjali Gupta",
          country: "India",
          title: "Recruiting and Retaining Women in Undergraduate Computing Majors",
        },
        {
          authors: "Eva Cheng, Jenine Beekhuyzen Oam and Marco Angelini",
          country: "Australia",
          title: "STEM x Play: Addressing early barriers to girls’ STEM participation through curriculum-integrated primary school outreach",
        },
      ]
    }
  ]

  const posterPresentations = [
    {
      header: "Track 2: Encouraging students to consider computing in their future",
      abstracts: [
        {
          authors: "Samar Swaid, Taima Suid and Ibrahim Suid",
          country: "United States",
          title: "Football is Female: Computational Thinking Focused Program for K-12",
        },
      ]
    },
    {
      header: "Track 4: Initiatives that explore the contribution of computing to society",
      abstracts: [
        {
          authors: "Arfel Aguilar, Marissa Chua, Danica Kate Mendoza, Eric Balarmino, Zyryl Garcia, Jennelyn Cuales and Jeffrey Calim",
          country: "Philippines",
          title: "ePT: A Recovery Application for Stroke Patients",
        },
        {
          authors: "Samar Swaid and Taima Suid",
          country: "United States",
          title: "Teaching Design Thinking in Data Science Curriculum",
        },
        {
          authors: "Anne Beatrice Belisario",
          country: "Philippines",
          title: "Automated Class Record System for Academic Performance Monitoring of Senior High School Learners",
        },
        {
          authors: "Ma. Melanie Cruz and Thelma Palaoag",
          country: "Philippines",
          title: "GREENTECH: Re-Enforcing Smart Waste Recycling using Mobile Application",
        },
      ]
    },
    {
      header: "Track 6: Computing Technologies",
      abstracts: [
        {
          authors: "Rui Li, Zhiguang Chen and Qingbo Wu",
          country: "China",
          title: "Rethinking the AI Infrastructure Based on High Performance Computing System",
        },
        {
          authors: "Asma Ali and Atif Ali",
          country: "Pakistan",
          title: "An Efficient Resource Prediction of Heterogeneous Workload in Cloud Hosted Datacentres Using Neural Networks",
        },
        {
          authors: "Jesse Mari Terence Crisostomo, Jaime Mari Alfonso Crisostomo, Reyae Mae Lubuguin Villamin, Rimar Cordon, Joshua Parayno and Bernie Fabito",
          country: "Philippines",
          title: "Factors affecting Continued Use of Twitter: An analysis using an Extended Information System Success Model (ISSM)",
        },
        {
          authors: "Jelyn Salamanca, Nadine Crisya Magbanua, Lae Jeciel Anniver Valdez, Liv Maoli Santiago and Bernie Fabito ",
          country: "Philippines",
          title: "Exploring the Behavioral Intention (BI) to Play Mobile Legends: Bang Bang: An Analysis Using the Theory of Planned Behavior",
        },
        {
          authors: "Ying Qian, Xing Yang, Chenhui Li and Changbo Wang",
          country: "China",
          title: "A virtual teaching platform of supercomputer: Exploration of virtual parallel programming practice",
        },
        {
          authors: "Armida Salazar",
          country: "Philippines",
          title: "Towards the Development of a Fake News Detection Web Agent for Philippine News Tweets",
        },
        {
          authors: "May Lynn Farren",
          country: "-",
          title: "Support Management System with Data Analytics",
        },
      ]
    },
    {
      header: "Track 7: Host Country corner",
      abstracts: [
        {
          authors: "Tanxia Claire Tobias",
          country: "Philippines",
          title: "Game-Based Learning Platform and Its Effects on Vocabulary Mastery in Online ESL Classroom",
        },
        {
          authors: "Ailah Marie Posadas, Kenny Carulasan, Ezllyson Gaebrielle Sagabaen, Precious Rean De Leon, Cherry Nicole Catapang, Jashlee Arellano and Bernie Fabito",
          country: "Philippines",
          title: "Predicting the Online Purchase Intention of Online shoppers in Lazada and Shopee",
        },
        {
          authors: "Veronica Vein Fajardo, Jovel Daniella Envase, Angelika De Guia, Zyra Macaire Cruz, Julienne Denolo and Bernie Fabito",
          country: "Philippines",
          title: "Exploring the Factors Influencing College Students’ Intention to Use Online Dating Sites",
        },
        {
          authors: "Cherry Rose Concha and Aleta Fabregas",
          country: "Philippines",
          title: "Career Advising System using Apriori Algorithm with Elective Tracking",
        },
      ]
    },
    {
      header: "Track 8: Computing in CoVID time / Online Learning",
      abstracts: [
        {
          authors: "Atif Ali",
          country: "Pakistan",
          title: "Post Covid-19 Pandemic Project Management Skills and Challenges For It Project Managers",
        },
        {
          authors: "Michal Blanche Puyot, Feona Mari Barrantes, Joycris Baraquel, Kirsten Cassandra Canedo and Bernie Fabito",
          country: "Philippines",
          title: "Exploring the E-readiness and Satisfaction of Faculty Members during the COVID-19 Pandemic",
        },
        {
          authors: "Jazel Mae Cabongcal, Lealyn Sanita, Lenlyn Sanita, Erwin Mancao and Bernie Fabito",
          country: "Philippines",
          title: "Exploring the College Students’ Learning Satisfaction in Online Learning: Basis for Depression, Anxiety, and Stress Among Students",
        },
        {
          authors: "Liezl Joyce Comendador, Bettina Angela Catigbe, Clou Joseph Bulac, Aervin Brian Angeles and Bernie Fabito",
          country: "Philippines",
          title: "Impulsive Buying and the COVID- 19 Pandemic: The E-Marketplace Buying Behavior of Tertiary Students",
        },
        {
          authors: "Kyla Rhonna Nicolai Gesmundo, Eujean Lloyd Gonzales, Yvonne Maye Idos, Abigail Laviña, Lopex Zimmer Rax and Bernie Fabito",
          country: "Philippines",
          title: "Exploring the Behavior Intention of Dentistry Students to Use MS Teams for Online Learning",
        },
      ]
    }
  ]

  return (
    <Layout>
      <Navigation />
      <Banner title="Accepted Abstracts" />
      <SectionWrapper id="abstract-presentations">
        <SectionTitle>Abstract Presentation</SectionTitle>
        {abstractPresentations.map((track) => (
          <>
            <h5 className={"mt-6 mb-4 text-lg font-semibold"}>{track.header}</h5>
            <div className={"overflow-auto mb-10"}>
              <table className="min-w-full table-fixed border-2 border-gray-800" style={{minWidth: "768px"}}>
                <thead className="justify-between">
                <tr className="bg-gray-800">
                  <th className="w-2/6 px-4 py-2 text-gray-300 text-left align-top">Authors</th>
                  <th className="w-1/6 px-4 py-2 text-gray-300 text-left align-top">Country</th>
                  <th className="w-3/6 px-4 py-2 text-gray-300 text-left align-top">Title</th>
                </tr>
                </thead>
                <tbody className="bg-gray-200">
                {track.abstracts.map((abstract, i) => (
                  <tr className={i % 2 !== 0? "bg-gray-200" : "bg-white"}>
                    <td className="px-4 py-2 align-top">{abstract.authors}</td>
                    <td className="px-4 py-2 align-top">{abstract.country}</td>
                    <td className="px-4 py-2 align-top">{abstract.title}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </>
        ))}
      </SectionWrapper>
      <SectionWrapper id="poster-presentations">
        <SectionTitle>Poster Presentation</SectionTitle>
        {posterPresentations.map((track) => (
          <>
            <h5 className={"mt-6 mb-4 text-lg font-semibold"}>{track.header}</h5>
            <div className={"overflow-auto mb-10"}>
              <table className="min-w-full table-fixed border-2 border-gray-800" style={{minWidth: "768px"}}>
                <thead className="justify-between">
                <tr className="bg-gray-800">
                  <th className="w-2/6 px-4 py-2 text-gray-300 text-left align-top">Authors</th>
                  <th className="w-1/6 px-4 py-2 text-gray-300 text-left align-top">Country</th>
                  <th className="w-3/6 px-4 py-2 text-gray-300 text-left align-top">Title</th>
                </tr>
                </thead>
                <tbody className="bg-gray-200">
                {track.abstracts.map((abstract, i) => (
                  <tr className={i % 2 !== 0? "bg-gray-200" : "bg-white"}>
                    <td className="px-4 py-2 align-top">{abstract.authors}</td>
                    <td className="px-4 py-2 align-top">{abstract.country}</td>
                    <td className="px-4 py-2 align-top">{abstract.title}</td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          </>
        ))}
      </SectionWrapper>
      <SectionWrapper padding={"px-6 lg:px-16 py-4 mb-10"}>
        <p>The authors are expected to submit a digital poster and video presentation of their abstracts that participants and visitors may view at the ACM-W Asia Pacific Forum <a className="text-accent hover:text-primary-light" href={"https://acmwap2021.boards.net"} target={"_blank"} rel={"noreferrer"}>https://acmwap2021.boards.net</a> under Research Exhibit 2021.</p>
        <br />
        <p>Each accepted abstract will have its discussion thread where the poster and the video presentation will be available. Although registration is optional, authors are encouraged to register to not appear as Anonymous when they respond to the comments, questions, and other clarifications from the viewers.</p>
      </SectionWrapper>
      <Footer />
    </Layout>
  )
}

export default AcceptedAbstracts